import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useConnection from './useConnection';
import { Card ,CardContent,Container,Grid, Typography} from '@material-ui/core';

const useStyles = makeStyles({
    container: {
    padding: '16px',
    },
  });

const MainUI = (props) => {
    
    const[logs,setLogs]=useState([]);
    const {events ,setEvents}=useConnection(1);
    const classes = useStyles();

    useEffect(()=>{
        let msgs=[];
        setLogs(msgs);
        console.log(JSON.stringify(events));

    },[]);
       
  
        return(<Container className={classes.container}>
<Grid container>
                <Grid item xs={12}>
                    <Typography>Whatsapp Automation API </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                <Card id="msgs">
                    <CardContent>
                    {events.map((log)=>
                       <div key={log}>
                      {log}</div>
                       )}   
                    </CardContent>
               </Card>
                </Grid>
                 <Grid item xs={4}>
                    
                 </Grid>
            </Grid>
        </Container>
   );
    

}

export default MainUI;