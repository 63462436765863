import React from 'react';
import {Switch,Route,Redirect} from 'react-router-dom';
import MainUI from './MainUI';
import QR  from './QR'
import Chat from './Chat';
export class Main extends React.Component{

render(){
const MainUIPage=()=>{

    return (
        <MainUI/>
    );
}

const QRPage=()=>{
return (
    <QR/>
);
};

const ChatPage=()=>{
    return (
        <Chat/>
    );
    };

    return(
<div >
        <Switch>
            <Route exact path="/" component={QRPage}/>
            <Route path="/activate" component={QRPage}/>
            <Route  path="/chat" component={ChatPage}/>
        </Switch>
</div>

    );
}

}
