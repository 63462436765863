import React, { useState } from "react";

import useChat from "./useChat";
import { TextField ,Button,Grid} from '@material-ui/core';
const Chat = (props) => {
  const { messages, sendMessage } = useChat(1); 
  const [newMessage, setNewMessage] = React.useState("");
  const [phn,setPhn]=React.useState("");

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handlePhnChange = (event) => {
    setPhn(event.target.value);
  };
  const handleSendMessage = () => {
   
  };

const send= (phn,msg) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ to: phn,text:msg })
};
fetch('http://localhost:9000/send/msg/'+msg+'/to/'+phn, requestOptions)
    .then(response => response.json())
    .then(
        setNewMessage("")
    );
}

  return (
    <div className="chat-room-container">
      <div className="messages-container">
        <ol className="messages-list">
          {messages.map((message, i) => (
            <li
              key={i}
              className={`message-item ${
                message.ownedByCurrentUser ? "my-message" : "received-message"
              }`}
            >
              {message.body}
            </li>
          ))}
        </ol>
      </div>
      <Grid container spacing={3}>
          <Grid item xs={12} m={4}>
               <TextField
                 id="filled-multiline-static"
                 label="Phone"
                 value={phn}
                 defaultValue=""
                 variant="outlined"
                 onChange={handlePhnChange}
                />
          </Grid>
          <Grid item xs={12}>
               <TextField
                id="filled-multiline-static"
                label="Message"
                defaultValue=""
                variant="outlined"
                value={newMessage}
                onChange={handleNewMessageChange}
                />
          </Grid>
          <Grid item xs={12}>
               <Button 
                 variant="contained"
                 onClick={()=>send(phn,newMessage)} >
                    Send
              </Button>
          </Grid>
      </Grid>
    
         
   
     
    </div>
  );
};

export default Chat;