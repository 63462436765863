import logo from './logo.svg';
import './App.css';
import   {Main}   from './Main';
import {BrowserRouter } from 'react-router-dom';


function App() {


  return (
    <div className="App">
		<BrowserRouter>
			<div className="container">
				<Main/>
			</div>
		</BrowserRouter>
    </div>
  );
}

export default App;
